import { useTranslation } from 'react-i18next';
import slugify from 'slugify';
import { Rental } from 'types/api';

const useRentalSeo = (rental: Pick<Rental, 'slug' | 'city' | 'location' | 'published'>): string => {
    const { i18n } = useTranslation();
    if (!rental.published) {
        return `/${i18n.language === 'fr' ? 'locations' : 'rentals'}/private/${rental.slug}`;
    }
    let seo = 'villa';
    if (rental.city) {
        seo = `${seo}-${rental.city}`;
    }
    if (rental.location.title) {
        seo = `${seo}-${rental.location.title}`;
    }
    seo = slugify(seo).toLowerCase();

    return `/${i18n.language === 'fr' ? 'locations' : 'rentals'}/${seo}/${rental.slug}`;
};

export default useRentalSeo;
