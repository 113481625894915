import { graphql } from 'gatsby';
import useRentalSeo from 'hooks/useRentalSeo';
import useSSR from 'hooks/useSSR';
import React, { ReactElement, useEffect } from 'react';
import { Rental as RentalType } from 'types/api';

type Props = {
    data: {
        rental: RentalType;
    };
};

const RentalRedirect = ({ data: { rental } }: Props): ReactElement => {
    const url = useRentalSeo(rental);
    const { browser } = useSSR();
    useEffect(() => {
        if (browser) {
            window.location.href = url;
        }
    }, [browser, url]);
    return <></>;
};

export const query = graphql`
    query ($id: String) {
        rental(id: { eq: $id }) {
            published
            city
            slug
            location {
                title
            }
        }
    }
`;

export default RentalRedirect;
