const useSSR = (): { ssr: boolean; browser: boolean } => {
    const isSSR = typeof window === 'undefined';

    return {
        ssr: isSSR,
        browser: !isSSR,
    };
};

export default useSSR;
